import React, { useState } from 'react';
import { auth } from '../config/firebaseConfig'; // Ensure the correct path
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import {
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import { Divider } from "@mui/material";

const OtpAuthentication = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);

  // Function to setup reCAPTCHA
  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible', // or 'normal' for testing
      'callback': (response) => {
        // reCAPTCHA solved; request OTP
        requestOTP();
      },
      'expired-callback': () => {
        console.log('ReCAPTCHA expired, please try again.');
      }
    }, auth); // Pass the auth instance
  };

  // Function to request OTP
  const requestOTP = async () => {
    setupRecaptcha();
    const appVerifier = window.recaptchaVerifier;

    try {
      const result = await signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier);
      setConfirmationResult(result);
      setOtpSent(true);
      console.log("OTP sent successfully");
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  // Function to verify OTP
  const verifyOTP = async () => {
    try {
      await confirmationResult.confirm(otp);
      alert("Login successful!");
    } catch (error) {
      console.error("Invalid OTP:", error);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center" justify="center" >
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ marginTop: "5rem" }}>
          <CardHeader>
            <h2 align="center"> Vishudha Tagline Traders </h2>
            <Divider />
            <h2 align="center"> OTP Authentication </h2>
          </CardHeader>
          <CardBody>
            <Grid container spacing={2} justifyContent="center" marginTop="5rem">
              <Grid
                item
                xs={12}
                align="center"
                justifyContent="center"
                margin="auto"
              >
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  name="phoneNumber"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="text"
                  style={{ marginBottom: "1rem", width: "35vh", color: "black" }}
                  value={phoneNumber || ""}
                />
              </Grid>
              <Grid
                item
                xs={12}
                align="center"
                justifyContent="center"
                margin="auto"
              >
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={requestOTP}
                  style={{
                    textTransform: "capitalize",
                    width: "20vh",
                    borderRadius: "5px",
                  }}
                >
                  Request OTP
                </Button>
              </Grid>
              {otpSent && (
                <Grid
                  item
                  xs={12}
                  align="center"
                  justifyContent="center"
                  margin="auto"
                >
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                  />
                  <button onClick={verifyOTP}>Verify OTP</button>
                </Grid>
              )}
              <div id="recaptcha-container"></div>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
    </Grid >
  )
}
export default OtpAuthentication;
