import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';


// Tagline Traders web app's Firebase configuration
//const firebaseConfig = {
  // apiKey: "AIzaSyCWGQuhGtgzEyRZgNzHMRx-cT_xYUHE9II",
  // authDomain: "taglinetraders-2f03b.firebaseapp.com",
  // projectId: "taglinetraders-2f03b",
  // storageBucket: "taglinetraders-2f03b.appspot.com",
  // messagingSenderId: "384737113236",
  // appId: "1:384737113236:web:a9ab7009b13a9371163081"

// };
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkNkVdzIeTdnALeK6jpPTZX8IrCAkzD_0",
  authDomain: "tagline-traders-fb-auth.firebaseapp.com",
  projectId: "tagline-traders-fb-auth",
  storageBucket: "tagline-traders-fb-auth.firebasestorage.app",
  messagingSenderId: "441079922327",
  appId: "1:441079922327:web:26b3bf899fc60cf79c33d2",
  measurementId: "G-PBQB89L3GZ"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp); // Get the auth instance
auth.settings.appVerificationDisabledForTesting = true;

// Export only the needed functions and variables
export { auth };
